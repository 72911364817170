/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable unused-imports/no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'keen-slider/keen-slider.min.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

export default function ImageSection2({
  sectionData,
  data,
}: {
  data: any;
  sectionData: any;
}) {
  const animation = { duration: 30000, easing: (t: any) => t };
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: 'auto',
      spacing: 10,
    },
    renderMode: 'performance',
    mode: 'free-snap',
    loop: true,
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });

  return (
    <div className="grid w-full flex-col gap-6">
      {sectionData.title && (
        <div className="relative flex shrink-0 grow-0 flex-col items-center justify-start gap-0 bg-white">
          <p className="w-full shrink-0 grow-0 text-center text-xl font-bold text-[#38465b]">
            {sectionData.title}
          </p>
          {/* {sectionData.sectionTitle && (
          <a
          href="/katalog"
          className="relative flex items-center justify-center gap-2.5 px-4 py-0"
          >
            <p className="shrink-0 grow-0 text-left text-sm font-medium text-[#b18f5f]">
              Lihat semua tema
            </p>
            </a>
          )} */}
        </div>
      )}
      <div ref={sliderRef} className="keen-slider ">
        {sectionData.content.map(
          (galleryData: any, i: any) =>
            galleryData.image && (
              <a
                key={i}
                href={galleryData?.url ? galleryData.url : '#'}
                className="cursor-pointer"
              >
                <div className="aspect-[9/16] h-[260px] w-auto">
                  <img
                    className="keen-slider__slide h-full w-full object-cover"
                    src={galleryData.image.url}
                    style={{
                      borderRadius: `16px`,
                      border: '2px solid #F2F2F2',
                      // width: 'auto', // Allow the width to adjust based on the original aspect ratio
                      // maxHeight: '140px', // Set the maximum height
                    }}
                    alt="gallery"
                  />
                </div>
              </a>
            ),
        )}
      </div>
    </div>
  );
}
